
<div >


    <div  
        style="background-color: #fff; width: 100%; padding: 16px; box-shadow: 0 0 8px #ccc; margin-top: 10px; border-radius: 6px; margin: 15px; height: 110vh;">

       


        <div class="filter-container">
          <mat-form-field appearance="outline" *ngIf="!selfDoctor" >
            <mat-label style="font-size: 15px;">Doctor Name</mat-label>
            <mat-select [value]="docName" (selectionChange)="onDropdownSelectDocName($event.value)"
            (openedChange)="onDropdownOpen($event)">

              <mat-option style="font-size: 16px;" *ngFor="let item of doctorObj" [value]="item.doctorName">
                {{item.doctorName}}
              </mat-option>
            </mat-select>

          </mat-form-field>

            <mat-form-field  appearance="outline">
              <mat-label>From</mat-label>
              <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fromDate"
              (dateChange)="onDateChange($event)"   >
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label>To</mat-label>
              <input matInput [matDatepicker]="toPicker" [(ngModel)]="toDate"
              (dateChange)="onDateChangeToDate($event)"  >
              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
              <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>
          
            <div class="scheduleandAddNew-outer">
                <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                    <button class="btn btn-info pr-4 pl-4" (click)="getPaymentTransactions()"
                        style="background-color:#4687BF;font-size: 18px;padding:6px; margin-top: -15px;"
                       >Submit</button>
                </div>
            </div>
          
       
          
          
          
          
          </div> <br>
          <div style="display:flex; flex-direction: row; width: 100%; margin-bottom: 15px;">

            <div id="searchbox" class="form-control searchBox" placeholder="Start typing to search" name="sb"><i
                class="fa fa-search" style="color: #bfbebf; padding: 7px;"></i>

              <input type="text" [(ngModel)]="searchPatientBill"
                (keyup)="onSearchPatientbill($event)" placeholder="Search Transaction"
                style="padding: 3px; border: none; outline: none;width:90% ;">
              <i class="fa fa-close"  aria-hidden="true"
                style="color: #bfbebf;padding: 7px; cursor: pointer;"></i>
            </div>
           

            <button 
              style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;"
              mat-raised-button>
             
              Total Bill: <img src="/assets/images/icons8-rupee-48.png"  width="20" height="20" style="margin-right: 5px;">{{totalBillAmount}}</button>
              <button 
              style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;"
              mat-raised-button>Total Refund:<img src="/assets/images/icons8-rupee-48.png"  width="20" height="20" style="margin-right: 5px;">{{totalRefund}}</button>


              <button class="btn btn-outline-success" style="margin-left: 10px; border-radius: 6px;" (click)="DownloadExcel('Doctor_Patients_Records.xlsx')">
                Download Excel
              </button>
          </div>
          <!-- <div style="display: flex; width: 100%;">
          <div style="width: 60%;">
            <input matInput placeholder="Search Transactions" [(ngModel)]="searchPatientBill"  (keyup)="onSearchPatientbill($event)" class="search-bar"
             />
          </div>
        <div>Total Bill {{totalBillAmount}}</div>
      </div> -->
        <div class="table-wrapper">
          <table mat-table [dataSource]="paymentTransactions"  id="doctor-patients-records-table"  class="mat-elevation-z8 mat-table-sticky">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Payment Date</th>
              <td mat-cell *matCellDef="let order"> {{ order.createdAt | date: 'dd/MM/yyyy hh:mm a' }} </td>
            </ng-container>
            <ng-container matColumnDef="appointmentDate">
              <th mat-header-cell *matHeaderCellDef>Appointment Date</th>
              <td mat-cell *matCellDef="let order"> {{ order.AppointmentDate | date: 'dd/MM/yyyy hh:mm a' }} </td>
            </ng-container>
            <ng-container matColumnDef="patientName">
              <th mat-header-cell *matHeaderCellDef>Patient Name</th>
              <td mat-cell *matCellDef="let order"> {{ order.patientName }} </td>
            </ng-container>
            <ng-container matColumnDef="mobile">
              <th mat-header-cell *matHeaderCellDef>Mobile</th>
              <td mat-cell *matCellDef="let order"> {{ order.patientNumber }} </td>
            </ng-container>
          
            <ng-container matColumnDef="method">
              <th mat-header-cell *matHeaderCellDef>Payment Method</th>
              <td mat-cell *matCellDef="let order"> {{ order.method }} </td>
            </ng-container>
          
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Bill Status</th>
              <td mat-cell *matCellDef="let order"> {{ order.transactionStatus }} </td>
            </ng-container>
            <ng-container matColumnDef="transactionType">
              <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
              <td mat-cell *matCellDef="let order"> {{ order.transactionType }} </td>
            </ng-container>
          
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Total Payment</th>
              <td mat-cell *matCellDef="let order"> {{ order.amount }} </td>
            </ng-container>
            
            <ng-container matColumnDef="details">
              <th mat-header-cell *matHeaderCellDef>Link</th>
              <td mat-cell *matCellDef="let order">
                <a class="details-link" (click)="openDialog(transactionlogModal);showLog(order)"  >Detail</a>
              </td>
            </ng-container>
                    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        </div>
     
        </div>
        <ng-template #transactionlogModal let-modal>
          <div style="width: 100%; padding: 20px;">  
            <h4>Transaction Log</h4>
         <div style="color: #555; font-size: 16px;">{{hoveredTransactionlog}}</div>
          <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
            <button (click)="closeDialog()" style="background-color: #eee;
                        padding: 8px 16px;
                        border-radius: 6px;
                        color: #fff;
                        font-weight: bold;
                        border: 0px;
                        margin: 4px;
                        color: #444;
                        outline: none;">Cancel</button>
        
           
          </div>
          </div>
        </ng-template>